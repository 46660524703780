import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

export function initDatadogRum() {
  const {
    VITE_DATADOG_RUM_APPLICATION_ID,
    VITE_DATADOG_RUM_CLIENT_TOKEN,
    VITE_STAGE,
  } = import.meta.env;

  if (
    VITE_DATADOG_RUM_APPLICATION_ID &&
    VITE_DATADOG_RUM_CLIENT_TOKEN &&
    VITE_STAGE
  ) {
    datadogRum.init({
      applicationId: VITE_DATADOG_RUM_APPLICATION_ID,
      clientToken: VITE_DATADOG_RUM_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'wx',
      env: VITE_STAGE,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }
}
export function initDatadogLogs() {
  const { VITE_DATADOG_LOGS_CLIENT_TOKEN, VITE_STAGE } = import.meta.env;

  if (VITE_DATADOG_LOGS_CLIENT_TOKEN && VITE_STAGE) {
    datadogLogs.init({
      clientToken: VITE_DATADOG_LOGS_CLIENT_TOKEN,
      site: 'datadoghq.com',
      env: VITE_STAGE,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service: 'wx-client-browser-webapp',
    });
  } else {
    console.info(
      `DataDog browser logs NOT initialized, missing VITE_DATADOG_LOGS_CLIENT_TOKEN:${VITE_DATADOG_LOGS_CLIENT_TOKEN} or VITE_STAGE:${VITE_STAGE}`,
    );
  }
}
