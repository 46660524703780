import { MuiTypography } from '@/components/mui/Typography';
import { FormattedMessage } from 'react-intl';
import FountainLogo from '@/assets/svg/fountain-logo.svg';

export default function LegalFooter() {
  const privacyPolicy =
    import.meta.env.VITE_CUSTOM_PRIVACY_POLICY_URL ??
    'https://privacy.fountain.com/policies/en/?name=privacy-policy';

  return (
    <div className="flex flex-col items-center justify-center text-center">
      <a
        href="https://www.fountain.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center justify-center gap-1.5"
      >
        <MuiTypography variant="subtitle1">
          <FormattedMessage defaultMessage="Powered by" />
        </MuiTypography>
        <img
          src={FountainLogo}
          alt="Fountain Logo"
          className="mb-[2px] max-w-[70px]"
        />
      </a>

      <div className="mt-1 flex items-center gap-2">
        <a
          href={privacyPolicy}
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm text-wx-neutral-400"
        >
          <FormattedMessage defaultMessage="Privacy Policy" />
        </a>

        <span className="text-[6px] text-wx-neutral-400">•</span>

        <a
          href="https://privacy.fountain.com/policies/en/?name=terms-of-service"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm text-wx-neutral-400"
        >
          <FormattedMessage defaultMessage="Terms of Service" />
        </a>
      </div>
    </div>
  );
}
