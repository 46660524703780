import { ButtonProps, useButton } from '@mui/base';
import { prepareForSlot } from '@mui/base/utils';
import { ReactNode, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { type VariantProps } from 'tailwind-variants';
import { cx } from '@/themes/utils/cssUtils';
import { CircularProgress } from '@mui/material';
import { button } from './variants';

const LinkSlot = prepareForSlot(Link);

type ButtonVariants = VariantProps<typeof button>;
export interface MuiButtonProps
  extends ButtonVariants,
    Omit<ButtonProps, 'color'> {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  'data-testid'?: string;
}

const MuiButton = forwardRef<HTMLButtonElement, MuiButtonProps>(
  function MuiButton(
    {
      children,
      startIcon,
      endIcon,
      loading,
      fullWidth,
      to,
      href,
      className,
      'data-testid': dataTestId,
      ...props
    },
    ref,
  ) {
    const tv = button({
      variant: props.variant,
      size: props.size,
      color: props.color,
      theme: props.theme,
      loading,
      disabled: props.disabled,
      grouped: props.grouped,
      fullWidth,
    });

    const { getRootProps } = useButton({
      ...props,
      rootRef: ref,
    });

    const isLink = href || to;

    const Comp = isLink ? LinkSlot : 'button';
    const linkProps = isLink
      ? {
          to,
          href,
          role: 'link',
        }
      : undefined;

    return (
      <Comp
        {...getRootProps()}
        {...linkProps}
        style={props.style}
        className={cx(tv.base(), className)}
        data-testid={dataTestId}
      >
        {startIcon && <div className={tv.startIcon()}>{startIcon}</div>}
        {children}
        {loading ? (
          <div className={tv.endIcon()}>
            <CircularProgress
              size={props.size === 'small' ? '0.875rem' : '1rem'}
              thickness={4}
              color="inherit"
            />
          </div>
        ) : (
          endIcon && <div className={tv.endIcon()}>{endIcon}</div>
        )}
      </Comp>
    );
  },
);

/**
 * A wrapper to control and override the API of buttons.
 */
export { MuiButton };
